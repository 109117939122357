@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
body {
  margin: 0;
  font-family: 'Share Tech Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}
h1{
  
  font-family: 'Share Tech Mono', monospace;
}
h5{
  
  font-family: 'Share Tech Mono', monospace;
}
.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }
p{
  
  font-family: 'Share Tech Mono', monospace;
  font-size:24px;
  text-align: justify;
}
a{
  
  font-family: 'Share Tech Mono', monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.jumbotron{
  top:0;
}
.above-div{
  background-color:rgba(255,255,255,0.5) !important
}
